module UtilService {
    let app = angular.module("app");

    app.factory("UtilService", ["$resource", "GlobalApplicationData", ($resource: ng.resource.IResourceService, GlobalApplicationData: any) => {
        let serv = {
            findUsersByTenant: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/find-users-by-tenant', {
            }, {
                query: {
                    method: 'GET',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera tutti gli utenti di uno specifico tenant (senza eseguire anche il count)    
            getUsersByTenant: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/get-users-by-tenant', {
            }, {
                query: {
                    method: 'GET',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera tutti gli utenti di uno specifico tenant (senza eseguire anche il count)    
            getUsersByUserIds: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/get-users-by-userIds', {
            }, {
                query: {
                    method: 'POST',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera il numero degli utenti totali di uno specifico tenant
            countUsersByTenant: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/count-users-by-tenant', {
            }, {
                query: {
                    method: 'GET',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera tutti i docenti   
            getTeachers: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/get-supplier-people-list', {
            }, {
                query: {
                    method: 'GET',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera il numero dei docenti
            countTeachers: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/count-supplier-people-list', {
            }, {
                query: {
                    method: 'GET',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera tutti fornitori   
            getAnagSuppliers: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/get-suppliers', {
            }, {
                query: {
                    method: 'GET',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera il numero dei fornitori
            countAnagSuppliers: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/count-suppliers', {
            }, {
                query: {
                    method: 'GET',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera la lista dei gruppi
            searchGroups: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/search-groups/:fromRecord/:numRecords', {
                fromRecord: "@fromRecord",
                numRecords: "@numRecords"
            }, {
                query: {
                    method: 'GET',
                    isArray: false
                }
            }, {
                cancellable: true
            }),


            // Recupera la lista dei gruppi
            getUserIdsByGroupIds: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/userIds-by-groupIds', {
            }, {
                query: {
                    method: 'POST',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Aggiunge gli utenti alla lista di supplier
            addUsersToSupplierList: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/add-users-to-supplier-people', {
            }, {
                query: {
                    method: 'POST',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Rimuove gli utenti alla lista dei docenti
            deleteUsersFromTeachers: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/delete-users-from-supplier-people', {
            }, {
                query: {
                    method: 'POST',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera un docente dato un id 
            getTeacherById: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/get-supplier-by-id', {
            }, {
                query: {
                    method: 'GET',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera il count dei gruppi
            countGroups: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/count-groups', {
            }, {
                query: {
                    method: 'GET',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Aggiunge una lista di utenti ad un gruppo
            addUsersToGroup: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/add-users-to-group', {
            }, {
                query: {
                    method: 'POST',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Crea un docente
            addTeacher: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/add-supplier-people', {
            }, {
                query: {
                    method: 'POST',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Crea un fornitore
            addAnagSupplierPeople: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/add-anag-supplier', {
            }, {
                query: {
                    method: 'POST',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Invia una mail per vedere la preview del template
            sendPreviewTemplateMail: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/send-preview-template-mail', {
            }, {
                query: {
                    method: 'POST',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Aggiorna un docente
            updateTeacher: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/update-supplier-people', {
            }, {
                query: {
                    method: 'POST',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            updateAnagSupplierPeople: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/update-anag-supplier', {
            }, {
                query: {
                    method: 'POST',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            deleteAnagSupplierPeople: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/delete-anag-supplier', {
            }, {
                query: {
                    method: 'POST',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Rimuove un gruppo
            deleteGroup: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/delete-group/:groupId', {
                groupId: "@groupId"
            }, {
                query: {
                    method: 'GET',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Crea un gruppo
            createGroup: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/create-group', {
            }, {
                query: {
                    method: 'POST',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Rimuove gli utenti selezionati dal gruppo
            removeUsersFromGroup: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/remove-users-from-group', {
            }, {
                query: {
                    method: 'POST',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Aggiorna il gruppo
            updateGroup: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/update-group', {
            }, {
                query: {
                    method: 'POST',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera le informazioni di un gruppo
            getGroupById: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/get-group-by-id/:groupId/:withUsersData', {
                groupId: "@groupId",
                withUsersData: "@withUsersData"
            }, {
                query: {
                    method: 'GET',
                    isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera le informazioni di un gruppo
            getUploadsByReference: $resource(GlobalApplicationData.applicationContext + 'rest-api/customersinvitations-mediator/get-all-upload-by-reference', {
            }, {
                query: {
                    method: 'GET',
                    isArray: false
                }
            }, {
                cancellable: true
            })
        };
        return serv;
    }]);
}